/* eslint-disable max-len */
import './PermissionsSwitchBoard.css';
import { UserGrantMutable } from '../../controllers/auth/types';

export type PermisionSubModule = {
  name: string,
  grant: UserGrantMutable,
  allowedGrants: UserGrantMutable[],
  subscriptionRequirement: string[],
  subText: string,
};

export type PermisionModule = {
  name: string,
  subText: string,
  subModules: PermisionSubModule[],
};

export const defaultPermisionsMap: PermisionModule[] = [
  {
    name: 'Organization Administration',
    subText: 'Provides access to settings and configuration pages',
    subModules: [
      {
        name: 'Item Administration',
        grant: 'PRODUCT_ADMIN',
        allowedGrants: ['ORG_ADMIN'],
        subscriptionRequirement: [],
        subText: 'Provides ability to add and edit tracked products',
      },
      {
        name: 'SOV Administration',
        grant: 'SOV_ADMIN',
        allowedGrants: ['ORG_ADMIN'],
        subscriptionRequirement: [],
        subText:
          'Provides ability to add, modify, and delete search terms and groups',
      },
      {
        name: 'User Administration',
        grant: 'USER_ADMIN',
        allowedGrants: ['ORG_ADMIN'],
        subscriptionRequirement: [],
        subText: 'Provides ability to add, modify, and delete user accounts',
      },
    ],
  },
  {
    name: 'Product Page Monitoring',
    subText:
      'Provides access to all Availability, Pricing, Reviews, Content, Marketplace submodules',
    subModules: [
      {
        name: 'Availability',
        grant: 'AVAILABILITY_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['product_page', 'product_seller_info'],
        subText: 'Provides access to all Availability dashboards',
      },
      {
        name: 'Pricing',
        grant: 'PRICING_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['product_page', 'product_seller_info'],
        subText: 'Provides access to all Pricing dashboards',
      },
      {
        name: 'Reviews',
        grant: 'REVIEW_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: [
          'product_page',
          'product_seller_info',
          'product_reviews',
        ],
        subText: 'Provides access to all Review dashboards',
      },
      {
        name: 'Content',
        grant: 'CONTENT_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['product_page', 'product_seller_info'],
        subText: 'Provides access to all Content dashboards',
      },
      {
        name: 'Marketplace',
        grant: 'MARKETPLACE_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['product_page', 'product_seller_info'],
        subText: 'Provides access to all Marketplace dashboards',
      },
    ],
  },
  {
    name: 'Sales & Advertising',
    subText: 'Provides access to all POS and Advertising submodules',
    subModules: [
      {
        name: 'Amazon Sales',
        grant: 'AMAZON_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['vendor_central_pos', 'seller_central_pos'],
        subText: 'Provides access to all Amazon Sales dashboards',
      },
      {
        name: 'Amazon Advertising',
        grant: 'AMAZON_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['search_advertising', 'demand_side_platform'],
        subText: 'Provides access to all Amazon Advertising dashboards',
      },
      {
        name: 'Best Buy Advertising',
        grant: 'BEST_BUY_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['criteo_retail_media'],
        subText: 'Provides access to all Best Buy Advertising dashboards',
      },
      {
        name: 'Home Depot Advertising',
        grant: 'HOME_DEPOT_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['home_depot_advertising'],
        subText: 'Provides access to all Home Depot Advertising dashboards',
      },
      {
        name: 'Home Depot Sales',
        grant: 'HOME_DEPOT_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['home_depot_pos'],
        subText: 'Provides access to all Home Depot Sales dashboards',
      },
      {
        name: 'Lowe\'s Advertising',
        grant: 'LOWES_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['criteo_retail_media'],
        subText: 'Provides access to all Lowe\'s Advertising dashboards',
      },
      {
        name: 'Lowe\'s Sales',
        grant: 'LOWES_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['lowes_pos'],
        subText: 'Provides access to all Lowe\'s Sales dashboards',
      },
      {
        name: "Macy's Advertising",
        grant: 'MACYS_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['criteo_retail_media'],
        subText: 'Provides access to all Macy\'s Advertising dashboards',
      },
      {
        name: 'Marshall Omnichannel Advertising',
        grant: 'MARSHALL_OMNICHANNEL_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['marshall_omnichannel_advertising'],
        subText: 'Provides access to all omnichannel advertising dashboards',
      },
      {
        name: 'Marshall Omnichannel Promotions',
        grant: 'MARSHALL_OMNICHANNEL_PROMO_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['marshall_omnichannel_promotions'],
        subText: 'Provides access to all omnichannel promotions dashboards',
      },
      {
        name: 'Meijer Sales',
        grant: 'MEIJER_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['meijer_pos'],
        subText: 'Provides access to all Meijer Sales dashboards',
      },
      {
        name: 'Target Advertising',
        grant: 'TARGET_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['criteo_retail_media'],
        subText: 'Provides access to all Target Advertising dashboards',
      },
      {
        name: 'Target Sales',
        grant: 'TARGET_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['target_pos'],
        subText: 'Provides access to all Target Sales dashboards',
      },
      {
        name: 'Walmart Sales',
        grant: 'WALMART_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['walmart_luminate_pos', 'walmart_pos'],
        subText: 'Provides access to all Walmart Sales dashboards',
      },
      {
        name: 'Wayfair Advertising',
        grant: 'WAYFAIR_AD_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['wayfair_search_advertising'],
        subText: 'Provides access to all Wayfair Advertising dashboards',
      },
      {
        name: 'Wayfair Sales',
        grant: 'WAYFAIR_POS_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['wayfair_pos'],
        subText: 'Provides access to all Wayfair Sales dashboards',
      },
    ],
  },
  {
    name: 'Share of Voice',
    subText: 'Provides access to the Share of Voice submodule',
    subModules: [
      {
        name: 'Share of Voice Dashboards',
        grant: 'SOV_VIEWER',
        allowedGrants: ['VIEWER', 'ORG_ADMIN'],
        subscriptionRequirement: ['retailer_search_result'],
        subText: 'Provides access to all Share of Voice dashboards',
      },
    ],
  },
];
