import DCAInstructionsCriteoRetailMedia from '../components/DCAInstructions/DCAInstructionsCriteoRetailMedia';
import DCAInstructionsSCPOS from '../components/DCAInstructions/DCAInstructionsSCPOS';
import DCAInstructionsVCPOS from '../components/DCAInstructions/DCAInstructionsVCPOS';
import DCAInstructionsWalmartConnectAdvertising from '../components/DCAInstructions/DCAInstructionsWalmartConnectAdvertising';

export enum OfferedSubscriptionIdEnum {
  criteo_retail_media_bestbuy = '7be1e959-2e1e-4d29-a109-6957933ead35',
  criteo_retail_media_lowes = '0bab3019-d8c9-4774-91c0-d01572323a9e',
  criteo_retail_media_macys = 'b77bbb94-d807-40dc-9e16-f88ae7f51eaf',
  criteo_retail_media_target = '886cb5c4-ea08-4bc5-a8af-545eb15c9d11',

  seller_central_pos_amazon_ca = 'cce294a6-37e7-441a-8fe3-e92242f7deed',
  seller_central_pos_amazon_us = 'f39746e2-8513-45a2-80f6-ad67d3c57f43',

  vendor_central_pos_amazon_ca = '0a1f1fdb-e897-4d4f-ae51-68989a777050',
  vendor_central_pos_amazon_mx = '6ab62032-6174-459a-a948-2980cee2df1c',
  vendor_central_pos_amazon_uk = '39785a70-e7c2-4f62-95da-091640333e91',
  vendor_central_pos_amazon_us = 'e56afec2-771e-4b6a-98db-11326b77b46e',

  walmart_connect_advertising_us = '20ebcc5a-5322-48ba-b873-688174d86677',
}

// will want to expand (or delete) this when we want to show all subscription settings
export const OrganizationAdminVisibleOfferedSubscriptions: ReadonlyArray<string> = [
  OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca,
  OfferedSubscriptionIdEnum.seller_central_pos_amazon_us,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us,
  OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy,
  OfferedSubscriptionIdEnum.criteo_retail_media_lowes,
  OfferedSubscriptionIdEnum.criteo_retail_media_macys,
  OfferedSubscriptionIdEnum.criteo_retail_media_target,
  OfferedSubscriptionIdEnum.walmart_connect_advertising_us,
  // '6ed4de27-5ed1-4b41-b775-5afbde94351c', // Amazon Search Advertising - for testing ONLY
];

export const SubscriptionQueryTranslations: { [key: string]: { [queryParam: string]: string } } = {
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_us]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_target]: {
    code: '__criteo_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_lowes]: {
    code: '__criteo_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy]: {
    code: '__criteo_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_macys]: {
    code: '__criteo_oauth_code',
  },
};

export const SubscriptionSettingUniqueValues: { [key: string]: string[] } = {
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_us]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_target]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_lowes]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_macys]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.walmart_connect_advertising_us]: [
    'walmart_connect_advertiser_id',
    'walmart_connect_advertiser_name',
  ],
};

// https://developer-docs.amazon.com/amazon-shipping/docs/seller-central-urls
export const amazonSpAPIVendorCentralAuthUrls: { [marketplaceCode: string]: string } = {
  CA: 'https://vendorcentral.amazon.ca/apps/authorize/consent',
  MX: 'https://vendorcentral.amazon.com.mx/apps/authorize/consent',
  UK: 'https://vendorcentral.amazon.co.uk/apps/authorize/consent',
  US: 'https://vendorcentral.amazon.com/apps/authorize/consent',
};

// ! REVIEW ME
export const amazonSpAPISellerCentralAuthUrls: { [marketplaceCode: string]: string } = {
  CA: 'https://sellercentral.amazon.ca/apps/authorize/consent',
  US: 'https://sellercentral.amazon.com/apps/authorize/consent',
};

export const getAmazonSpAPIVendorCentralAuthUrl = (
  marketplaceCodes: string[],
): string | null => (
  (marketplaceCodes.length > 0 && marketplaceCodes[0] in amazonSpAPIVendorCentralAuthUrls)
    ? amazonSpAPIVendorCentralAuthUrls[marketplaceCodes[0]]
    : null
);

export const getAmazonSpAPISellerCentralAuthUrl = (
  marketplaceCodes: string[],
): string | null => (
  (marketplaceCodes.length > 0 && marketplaceCodes[0] in amazonSpAPISellerCentralAuthUrls)
    ? amazonSpAPISellerCentralAuthUrls[marketplaceCodes[0]]
    : null
);

// https://consent.criteo.com/request?response_type=code&client_id={client_id}&redirect_uri={redirect_uri}&state={state}
export const criteoAuthUrl = 'https://consent.criteo.com/request';
// client id = a26d6d2c5430499bb13f922b481deafd
// redirect_uri = 'https://localhost.dev.marshall.technology:3000/organizationAdmin'

export const paramsToHideOnRefresh = [
  'spapi_oauth_code',
  'code',
];

export const DisableAccountNameEntry: string[] = [
  OfferedSubscriptionIdEnum.criteo_retail_media_target,
  OfferedSubscriptionIdEnum.criteo_retail_media_lowes,
  OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy,
  OfferedSubscriptionIdEnum.criteo_retail_media_macys,
  OfferedSubscriptionIdEnum.walmart_connect_advertising_us,
];

export const dcaInstructions: { [key: string]: JSX.Element } = {
  [OfferedSubscriptionIdEnum.criteo_retail_media_target]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.criteo_retail_media_lowes]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.criteo_retail_media_macys]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca]: DCAInstructionsSCPOS,
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_us]: DCAInstructionsSCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca]: DCAInstructionsVCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx]: DCAInstructionsVCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk]: DCAInstructionsVCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us]: DCAInstructionsVCPOS,
  [OfferedSubscriptionIdEnum.walmart_connect_advertising_us]:
    DCAInstructionsWalmartConnectAdvertising,
  // used for testing
  subscription_id_vendor_central_pos: DCAInstructionsVCPOS,
};
