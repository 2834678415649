import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Button, Row, Table } from 'react-bootstrap';
import { v4 as genUUIDV4 } from 'uuid';
import './OrganizationAdmin.css';
import PageHeader from '../../components/PageHeader/PageHeader';
import { AsyncState } from '../../utils/webRequests.type';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import LoadingPage from '../LoadingPage/LoadingPage';
import {
  DataCollectionAccountWithSettings,
  OrganizationSubscription,
  OrganizationSubscriptionSetting,
  StoredRequestState,
  SubscriptionSettingsRequirement,
} from '../../controllers/subscription-service/types';
import {
  fetchOrganizationSubscriptionSettings,
  fetchOrganizationSubscriptions,
  fetchSubscriptionSettingsRequirements,
  getDataCollectionAccounts,
  updateDataCollectionAccount,
  deleteDataCollectionAccount,
  createDataCollectionAccount,
  updateDataCollectionAccountSettings,
} from '../../controllers/subscription-service';
import {
  OfferedSubscriptionIdEnum,
  OrganizationAdminVisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  criteoAuthUrl,
  getAmazonSpAPISellerCentralAuthUrl,
  getAmazonSpAPIVendorCentralAuthUrl,
} from '../../utils/dataCollectionConstants';
import { displayStringAsLabel, generateOAuthLocalKey } from '../../utils/stringFormatting';
import { Config, Trash } from '../../icons';
import DataCollectionAccountModal from '../../components/DataCollectionAccountModal/DataCollectionAccountModal';
import GlobalConfig from '../../app/config';
import sendRumError from '../../utils/datadogRum';
import SnackBar, { SnackBarState } from '../../components/snackBar/SnackBar';
import { DeleteModal } from '../../components/modal';
import { HelpLinkButton } from '../../components/modal/helpModal/helpModal';
import { getTodayAsString } from '../../utils/time';
import { parseFastAPIError } from '../../controllers';
import { oAuthLocalStoreKeyPrefix } from '../../utils/constants';

export default function OrganizationAdmin() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get('state');
  const [
    organizationSubscriptions,
    setOrganizationSubscriptions,
  ] = useState<OrganizationSubscription[]>([]);

  // List of unordered setting requirements.
  // Settings modal will receive a filtered copy of this.
  const [
    subscriptionSettingsRequirements,
    setSubscriptionSettingsRequirements,
  ] = useState<SubscriptionSettingsRequirement[]>([]);

  const currentOrgId = useSelector(selectCurrentOrgId);
  const [dataLoadState, setDataLoadState] = useState<AsyncState>('uninitialized');
  const [dataSettingLoadState, setDataSettingLoadState] = useState<AsyncState>('uninitialized');
  const [
    dataCollectionAccountWithSettings,
    setDataCollectionAccountWithSettings,
  ] = useState<DataCollectionAccountWithSettings[]>([]);

  const [activeDataCollectionAccount, setActiveDataCollectionAccount] = (
    useState<DataCollectionAccountWithSettings | null>(null)
  );
  const [messageMap, setMessage] = useState<SnackBarState>({
    alertText: '',
    alertType: 'danger',
    header: 'Error',
    show: false,
  });

  // used to initialize an "emptyDCA" element.
  const emptyDCA: DataCollectionAccountWithSettings = {
    id: '',
    organization_id: currentOrgId,
    offered_subscription_id: '',
    name: '',
    settings: [],
    population_status: 'empty',
    created_at: '',
    updated_at: '',
    deleted_at: '',
  };

  /* helpers */

  const compareOrgSubscriptions = (
    a: OrganizationSubscription,
    b: OrganizationSubscription,
  ): number => {
    if (a.retailer_name === b.retailer_name) {
      if (a.collection_type === b.collection_type) {
        return 0;
      }
      return a.collection_type > b.collection_type ? 1 : -1;
    }
    return a.retailer_name > b.retailer_name ? 1 : -1;
  };

  const compareRequirementDisplayOrder = (
    a: SubscriptionSettingsRequirement,
    b: SubscriptionSettingsRequirement,
  ): number => {
    if (a.display_order === b.display_order) {
      return 0;
    }
    return (a.display_order ?? 1000) > (b.display_order ?? 1000) ? 1 : -1;
  };

  const findDCAAttribute = (
    dca: DataCollectionAccountWithSettings,
    setting_key: string,
    defaultValue: string | string[],
  ) => {
    const setting = dca.settings.find(
      ({ key }) => key === setting_key,
    );
    return setting ? setting.value : defaultValue;
  };

  const sortOrganizationSubscriptions = (a: OrganizationSubscription[]) => {
    const futureOrgSubscriptions = a;
    futureOrgSubscriptions.sort(compareOrgSubscriptions);
    return futureOrgSubscriptions;
  };

  const sortRequirementDisplayOrder = (a: SubscriptionSettingsRequirement[]) => {
    const futureRequirements = a;
    futureRequirements.sort(compareRequirementDisplayOrder);
    return futureRequirements;
  };

  const configSPAPIParams = (
    dca: DataCollectionAccountWithSettings,
  ) => {
    const requestParams = new URLSearchParams();
    const newRequestId = genUUIDV4();
    const storedRequestState:StoredRequestState = {
      dca,
      timestamp: Date.now(),
    };

    localStorage.setItem(generateOAuthLocalKey(newRequestId), JSON.stringify(storedRequestState));
    const redirectUrl = new URL(`${window.origin}/organizationAdmin`);
    requestParams.set('state', newRequestId);
    requestParams.set('application_id', GlobalConfig.config.AMAZON_APPLICATION_ID);
    requestParams.set('redirect_uri', redirectUrl.toString());

    if (GlobalConfig.config.ENV !== 'production') {
      requestParams.set('version', 'beta');
    }
    return requestParams;
  };

  const authorizeAmazonApp = (
    dca: DataCollectionAccountWithSettings,
    urlFunc: (marketplaceCodes: string[]) => (string | null),
  ) => {
    let marketplaceCodes = findDCAAttribute(dca, 'marketplace_country_codes', ['US']);

    // Expecting a list of strings.. if I'm getting a complex object, wipe and reset it.
    if (!Array.isArray(marketplaceCodes) && typeof marketplaceCodes === 'object') {
      marketplaceCodes = [];
    }

    const amazonAuthUrl = urlFunc(
      Array.isArray(marketplaceCodes) ? marketplaceCodes : [marketplaceCodes],
    );

    const params = configSPAPIParams(dca);

    if (!amazonAuthUrl) {
      sendRumError(new Error('Unable to locate authorization for marketplace.'));
      setMessage({
        header: 'Error',
        alertText: 'Unable to locate authorization for marketplace.',
        alertType: 'danger',
        show: true,
      });
      return;
    }
    const spApiUrl = new URL(amazonAuthUrl);
    spApiUrl.search = params.toString();
    window.open(spApiUrl, '_self');
  };

  const configCriteoParams = (
    dca: DataCollectionAccountWithSettings,
  ) => {
    const requestParams = new URLSearchParams();
    const newRequestId = genUUIDV4();
    const storedRequestState:StoredRequestState = {
      dca,
      timestamp: Date.now(),
    };

    localStorage.setItem(generateOAuthLocalKey(newRequestId), JSON.stringify(storedRequestState));
    const redirectUrl = new URL(`${window.origin}/organizationAdmin`);
    requestParams.set('response_type', 'code');
    requestParams.set('state', newRequestId);
    requestParams.set('client_id', GlobalConfig.config.CRITEO_CLIENT_ID);
    requestParams.set('redirect_uri', redirectUrl.toString());

    return requestParams;
  };

  const authorizeCriteoApp = (
    dca: DataCollectionAccountWithSettings,
  ) => {
    const params = configCriteoParams(dca);

    const criteoUrl = new URL(criteoAuthUrl);
    criteoUrl.search = params.toString();
    window.open(criteoUrl, '_self');
  };

  const getDuplicatedValues = (
    dca: DataCollectionAccountWithSettings,
  ): string[] => {
    // return false if there isn't a set of attributes to check
    if (!(dca.offered_subscription_id in SubscriptionSettingUniqueValues)) {
      return [];
    }
    // helper to record what I want to compare in a little object.
    const dcaValues: OrganizationSubscriptionSetting[] = dca.settings.reduce((
      agg: OrganizationSubscriptionSetting[],
      row: OrganizationSubscriptionSetting,
    ) => {
      if (SubscriptionSettingUniqueValues[dca.offered_subscription_id].includes(row.key)) {
        agg.push(row);
      }
      return agg;
    }, []);

    // checks to see for each existing DataCollectionAccount
    // if the offered_subscription, selected keys and values
    // match (and not the dataCollectionAccountID.. don't want
    // to match with self)
    const duplicatedKeys = [
      ...(dataCollectionAccountWithSettings.filter(
        ({ id, offered_subscription_id: offeredSubscriptionId }) => (
          id !== dca.id
          && offeredSubscriptionId === dca.offered_subscription_id
        ),
      ).reduce<Set<string>>(
        (acc, { name, settings }) => {
          const nameMatch = (name === dca.name);

          // key keys of matching values
          const res = settings
            .filter(
              (
                {
                  key: settingKey,
                  value: settingValue,
                },
              ) => (
                dcaValues
                  .some(
                    (
                      {
                        key: dcaKey,
                        value: dcaValue,
                      },
                    ) => dcaKey === settingKey && settingValue === dcaValue,
                  )
              ),
            )
            .map(({ key }) => key);

          if (nameMatch) {
            res.push('account_name');
          }
          // add values to set
          res.forEach(acc.add, acc);
          return acc;
        },
        new Set(),
      )
      )];

    const res = duplicatedKeys.map(
      (dupKey) => {
        const requirement = subscriptionSettingsRequirements
          .find(({ setting_key, offered_subscription_id }) => (
            setting_key === dupKey
            && offered_subscription_id === dca.offered_subscription_id
          ));

        return requirement ? requirement.input_label : dupKey;
      },
    );
    return res;
  };

  const reloadDCAs = () => {
    // reload data
    setDataLoadState('loading');
    getDataCollectionAccounts(currentOrgId)
      .then(({ data }) => setDataCollectionAccountWithSettings(
        data.map(
          (d) => ({
            ...d,
            settings: [],
          }),
        ),
      ))
      // trigger the settings re-load.
      .then(() => {
        setDataLoadState('completed');
        setDataSettingLoadState('uninitialized');
      })
      .catch((error) => {
        sendRumError(error);
        setMessage({
          header: 'Error',
          alertText: 'Unable to load updated Data Collection Accounts.',
          alertType: 'danger',
          show: true,
        });
        setDataLoadState('failed');
      });
  };

  // saving settings to an existing DCA.
  const requestDCASave = (
    targetDCA: DataCollectionAccountWithSettings,
  ) => {
    // state is a misnomer.. should be 'working'
    setMessage({
      header: 'Validating',
      alertText: 'Please wait while we validate and save these settings.',
      alertType: 'info',
      show: true,
    });

    // Check for duplicates
    const duplicatedKeys = getDuplicatedValues(targetDCA);
    const joinedDupes = duplicatedKeys.join(', ');
    if (duplicatedKeys.length > 0) {
      sendRumError(new Error(`Duplicate values ${joinedDupes} detected.  Not saving`));
      setMessage({
        header: 'Error',
        alertText: `Duplicate values detected for: (${joinedDupes}).  Settings are not saved.`,
        alertType: 'danger',
        show: true,
      });
      return;
    }

    // test to see if we need to do an external Auth... and do it
    switch (targetDCA.offered_subscription_id) {
      // authorizeAmazonApp - SPAPI - SC POS
      case OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca:
      case OfferedSubscriptionIdEnum.seller_central_pos_amazon_us:
        // If don't have a recent oauth, request one
        if (!targetDCA.settings.some(
          ({ key }) => key === '__spapi_oauth_code',
        )) {
          authorizeAmazonApp(
            targetDCA,
            getAmazonSpAPISellerCentralAuthUrl,
          );
          return;
        }
        break;
      // authorizeAmazonApp - SPAPI - VC POS
      case OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca:
      case OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx:
      case OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk:
      case OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us:
        // If don't have a recent oauth, request one
        if (!targetDCA.settings.some(
          ({ key }) => key === '__spapi_oauth_code',
        )) {
          authorizeAmazonApp(
            targetDCA,
            getAmazonSpAPIVendorCentralAuthUrl,
          );
          return;
        }
        break;
        // authorizeCriteoApp
      case OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy:
      case OfferedSubscriptionIdEnum.criteo_retail_media_lowes:
      case OfferedSubscriptionIdEnum.criteo_retail_media_macys:
      case OfferedSubscriptionIdEnum.criteo_retail_media_target:
        // If don't have a recent oauth, request one
        if (!targetDCA.settings.some(
          ({ key }) => key === '__criteo_oauth_code',
        )) {
          authorizeCriteoApp(
            targetDCA,
          );
          return;
        }
        break;
      default:
      // pass
    }

    // Check against offered subcription + valid dates.
    // valid date: anything that hasn't expired (don't care about start_date)
    const orgSub = organizationSubscriptions.find(
      ({ offered_subscription_id, deactivated_at }) => (
        offered_subscription_id === targetDCA.offered_subscription_id
        && deactivated_at >= getTodayAsString()
      ),
    );

    if (!orgSub) {
      sendRumError(new Error('Can not find subscription'));
      setMessage({
        header: 'Error',
        alertText: 'Unable to locate subscription. Settings are not saved.',
        alertType: 'danger',
        show: true,
      });
      return;
    }

    // smash the key-values from the list into a simple dictionary.
    const settingsDict = Object.fromEntries(targetDCA.settings.map(
      ({ key, value }) => [key, value],
    ));

    /* create a new DCA + settings */
    if (targetDCA.id === '') {
      createDataCollectionAccount(
        currentOrgId,
        { // DataCollectionAccountPayload
          organization_id: currentOrgId,
          name: targetDCA.name,
          offered_subscription_id: targetDCA.offered_subscription_id,
          // subscription-service will self-set this to the correct value
          population_status: 'empty',
          settings: settingsDict,
          orgadmin_uri: window.location.href.split('?')[0],
        },
      ).then(
        () => {
          setMessage({
            header: 'Saved',
            alertText: 'The settings have been saved.',
            alertType: 'success',
            show: true,
          });
          reloadDCAs();
        },
      ).catch(
        (error) => {
          setMessage({
            header: 'Error',
            alertText: `Failed to save data collection account settings. ${error.response.data.detail.msg}`,
            alertType: 'danger',
            show: true,
          });
        },
      );
    } else {
      // update DCA
      updateDataCollectionAccount(
        currentOrgId,
        targetDCA.id,
        { // DataCollectionAccountUpdatePayload
          name: targetDCA.name,
        },
      ).then(
        () => {
          // update settings
          updateDataCollectionAccountSettings(
            currentOrgId,
            targetDCA.id,
            { // DataCollectionAccountSettingsUpdatePayload
              settings: settingsDict,
            },
          ).then(
            () => {
              setMessage({
                header: 'Saved',
                alertText: 'The settings have been saved.',
                alertType: 'success',
                show: true,
              });
              reloadDCAs();
            },
          ).catch(
            (error) => {
              const fastAPIErrors = parseFastAPIError(error);
              setMessage({
                header: 'Error',
                alertText: `Failed to update data collection account settings.${fastAPIErrors ? ` ${fastAPIErrors.join('\n')}` : ''}`,
                alertType: 'danger',
                show: true,
              });
            },
          );
        },
      ).catch(
        (error) => {
          const fastAPIErrors = parseFastAPIError(error);

          setMessage({
            header: 'Error',
            alertText: `Failed to update data collection account settings.${fastAPIErrors ? ` ${fastAPIErrors.join('\n')}` : ''}`,
            alertType: 'danger',
            show: true,
          });
        },
      );
    }
  };

  const deleteDCA = (
    dca: DataCollectionAccountWithSettings,
  ) => {
    deleteDataCollectionAccount(
      currentOrgId,
      dca.id,
    ).then(() => {
      setMessage({
        header: 'Deleted',
        alertText: 'Data Collection Account has been removed.',
        alertType: 'info',
        show: true,
      });
      reloadDCAs();
    }).catch((error) => {
      sendRumError(error);
      setMessage({
        header: 'Error',
        alertText: 'Unable to remove Data Collection Account.',
        alertType: 'danger',
        show: true,
      });
    });
  };

  // get DCA from passed URI query parameters
  const getDCAFromQuery = (): (DataCollectionAccountWithSettings | null) => {
    try {
      // this is now a generic oauth key.  since it don't have anything special to SP-API
      const storedRequestKey = generateOAuthLocalKey(requestId ?? 'undefined');

      const storedRequestString = localStorage.getItem(storedRequestKey);
      localStorage.removeItem(storedRequestKey);

      const storedRequestState: StoredRequestState = storedRequestString
        ? JSON.parse(storedRequestString)
        : undefined;

      if (
        storedRequestState
        && storedRequestState.dca.organization_id.toString() !== String(currentOrgId)
      ) {
        setMessage({
          show: true,
          header: 'Error: Bad Authorization',
          alertText: 'The received authorization belongs to a different organization.',
          alertType: 'danger',
        });
        return null;
      }
      const { dca } = storedRequestState as { dca:DataCollectionAccountWithSettings };

      const incomingSettings = Object.entries(SubscriptionQueryTranslations[
        storedRequestState.dca.offered_subscription_id
      ]).filter(([queryParam]) => searchParams.get(queryParam))
        .map<OrganizationSubscriptionSetting>(
        ([queryParam, settingParam]) => ({
          id: '',
          organization_id: dca.organization_id,
          data_collection_account_id: dca.id,
          key: settingParam,
          value: searchParams.get(queryParam) as string,
          population_status: 'filled',
          expires_at: '',
        }),
      );
      dca.settings.push(...incomingSettings);
      return dca;
    } catch (error) {
      sendRumError(error);
      setMessage({
        header: 'Error',
        alertText: 'Unable to translate Data Collection Account from Authorizing Retailer.',
        alertType: 'danger',
        show: true,
      });
      return null;
    }
  };

  // handle DCA if it is passed from URI parameters
  const handleDCAFromQuery = () => {
    try {
      const queryDCA = getDCAFromQuery();
      if (queryDCA) {
        // scrub the navigation URI.
        // This will replace the current entry in the browser's history, without reloading
        navigate('.', { replace: true });
        // // Save it.  Won't bother to update the DCA list, we're going to reload after save.
        requestDCASave(queryDCA);
      }
    } catch (error) {
      sendRumError(error);
      setMessage({
        header: 'Error',
        alertText: 'Issue with handling Data Collection Account from Authorizing Retailer.',
        alertType: 'danger',
        show: true,
      });
    }
  };

  // merges settings into the DCA structure.
  const mergeSettingsIntoDCA = (
    settings: OrganizationSubscriptionSetting[],
  ) => {
    setDataCollectionAccountWithSettings(
      dataCollectionAccountWithSettings.map(
        (dca) => ({
          ...dca,
          settings: settings.filter(
            ({ data_collection_account_id }) => data_collection_account_id === dca.id,
          ),
        }),
      ),
    );
    setActiveDataCollectionAccount(null);
  };

  // Load data
  useEffect(() => {
    if (currentOrgId) {
      setDataLoadState('loading');

      // - load organization subscriptions
      const organizationSubscriptionsPromise = fetchOrganizationSubscriptions(currentOrgId)
        .then(({ data }) => {
          setOrganizationSubscriptions(
            sortOrganizationSubscriptions(
              data.filter(
                ({
                  offered_subscription_id,
                  deactivated_at,
                }) => (
                // filter for org subs we intent to allow configuration on
                  OrganizationAdminVisibleOfferedSubscriptions.includes(
                    offered_subscription_id,
                  ))
                // filter for active subscriptions
              && (deactivated_at === undefined || Date.parse(deactivated_at) > Date.now()),
              ),
            ),
          );
        });

      // - load offered_subscription_requirements
      const offeredSubscriptionsRequirementsPromise = fetchSubscriptionSettingsRequirements(
        currentOrgId,
      )
        .then(({ data }) => {
          setSubscriptionSettingsRequirements(
            sortRequirementDisplayOrder(data),
          );
        });

      // - load DCAs, without settings
      const getDCAsPromise = getDataCollectionAccounts(currentOrgId)
        .then(({ data }) => setDataCollectionAccountWithSettings(
          data.map(
            (d) => ({
              ...d,
              settings: [],
            }),
          ),
        ));

      Promise.allSettled([
        organizationSubscriptionsPromise,
        offeredSubscriptionsRequirementsPromise,
        getDCAsPromise,
      ])
        .then(
          (results) => {
            const allGood = results.every((result) => result.status === 'fulfilled');
            setDataLoadState(allGood ? 'completed' : 'failed');
          },
        );
    }
  }, [currentOrgId]);

  // Load Setting data, and merge it into DCA structure.
  useEffect(() => {
    if (dataLoadState !== 'completed' || dataSettingLoadState !== 'uninitialized') {
      return;
    }

    setDataSettingLoadState('loading');

    fetchOrganizationSubscriptionSettings(currentOrgId)
      .then(({ data }) => {
        mergeSettingsIntoDCA(data);
        setDataSettingLoadState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setDataSettingLoadState('failed');
      });
  }, [dataLoadState, dataSettingLoadState]);

  // Load search Params, if present.
  useEffect(() => {
    // clean up old state older than 1 hour
    const now = Date.now();
    Object.entries(localStorage)
      .filter(([key, value]) => {
        if (key.startsWith(oAuthLocalStoreKeyPrefix)) {
          const parsedValue = JSON.parse(value as string);
          return (parsedValue.timestamp + (1000 * 60 * 60)) < now;
        }
        return false;
      })
      .forEach(([key]) => localStorage.removeItem(key));

    if (dataSettingLoadState === 'completed'
      && requestId
    ) {
      handleDCAFromQuery();
    }
  }, [dataSettingLoadState, requestId]);

  // Display stuff (if you can)
  if ([dataLoadState, dataSettingLoadState].some(
    (ele) => ['loading', 'uninitialized'].includes(ele),
  )) {
    return <LoadingPage />;
  }

  const availableSubscriptions = organizationSubscriptions.filter(
    (orgSub) => (
      OrganizationAdminVisibleOfferedSubscriptions.includes(
        orgSub.offered_subscription_id,
      )
    ),
  );

  return (
    <div className="page settings-page h-100">
      <PageHeader title="Manage Organization Settings" returnText="Back to Home" returnLink="/">
        <Button
          className="add-account-button pull-right"
          onClick={() => setActiveDataCollectionAccount(emptyDCA)}
          data-testid="addDCA"
          hidden={organizationSubscriptions.length === 0}
        >
          Add Account
        </Button>
      </PageHeader>
      <div
        /* ? Needed for testing href changes in jest */
        id="location-mirror"
        data-testid="location-mirror"
        className="d-none"
      >
        {window.location.href}
      </div>
      <div className="PageContent row mt-3">
        <div className="h-100 col-3">
          <h5>
            Subscriptions:
          </h5>
          {
            /* Future: also show status and count of data_collection_accounts */
            /* Nagivation links to right pane */
            organizationSubscriptions.map(
              (subscription) => (
                <a
                  key={subscription.id}
                  className="ma-link d-block"
                  href={`#subscription-${subscription.id}`}
                >
                  {subscription.retailer_name}
                  &nbsp;-&nbsp;
                  {displayStringAsLabel(subscription.collection_type)}
                </a>
              ),
            )
          }
        </div>

        <div className="h-100 col-9">
          <SnackBar
            style={{
              position: 'absolute', width: '50vw', zIndex: 10000,
            }}
            header={messageMap.header}
            alertText={messageMap.alertText}
            alertType={messageMap.alertType}
            show={messageMap.show}
            onClose={() => setMessage({
              show: false, alertText: '', alertType: 'danger', header: 'Error',
            })}
          />
          {
            activeDataCollectionAccount !== null ? (
              <DataCollectionAccountModal
                settingRequirements={subscriptionSettingsRequirements}
                dataCollectionAccount={activeDataCollectionAccount}
                subscriptions={organizationSubscriptions}
                onSave={requestDCASave}
                onClose={() => setActiveDataCollectionAccount(null)}
              />
            ) : null
          }
          <div
            className="row right-pane-header"
          >
            <h3
              className="p-0"
            >
              <b>
                Data Collection Accounts
              </b>
            </h3>
          </div>
          <br />
          {
            availableSubscriptions.map((orgSub) => (
              <div
                key={`subscription-header--${orgSub.offered_subscription_id}$`}
                className="row subscription-accounts"
              >
                <div
                  className="row mt-3 mb-1 ps-0 border-bottom border-secondary"
                >
                  <h4>
                    {orgSub.retailer_name}
                    &nbsp;-&nbsp;
                    {displayStringAsLabel(orgSub.collection_type)}
                  </h4>
                </div>
                <div
                  hidden={dataCollectionAccountWithSettings.filter(
                    ({ offered_subscription_id, deleted_at }) => (
                      offered_subscription_id === orgSub.offered_subscription_id
                      && deleted_at === null
                    ),
                  ).length > 0}
                  className="mb-1"
                >
                  There are no configured accounts for this subscription.
                  Please add an account to continue.
                </div>
                {
                  dataCollectionAccountWithSettings.filter(
                    ({ offered_subscription_id, deleted_at }) => (
                      offered_subscription_id === orgSub.offered_subscription_id
                      && deleted_at === null
                    ),
                  ).map((dca) => (
                    <div
                      key={`dca-${dca.id}`}
                      className="row m-0"
                    >
                      <div
                        className="col-2"
                      >
                        &nbsp;
                      </div>
                      <div
                        className="col-10 pb-1 ps-4 border-bottom border-secondary data-collection-account"
                      >
                        <div className="d-flex">
                          <strong>
                            {dca.name}
                          </strong>
                          <div className="ms-auto me-0">
                            <Button
                              className="ms-3 ma-btn ma-btn-link"
                              onClick={() => setActiveDataCollectionAccount(dca)}
                              data-testid={`editDCA-${dca.id}`}
                            >
                              <Config fill="var(--endurance-blue)" />
                            </Button>
                            <DeleteModal
                              buttonChildren={<Trash fill="var(--blushing-salmon)" />}
                              buttonClassName="ma-btn ma-btn-link ms-3"
                              challengeText={dca.name}
                              title="Delete Account"
                              onDelete={() => deleteDCA(dca)}
                              onHide={() => setActiveDataCollectionAccount(null)}
                              deleteElement="Delete"
                              disabled={false}
                            >
                              <Row>
                                <p>Are you sure you want to delete this account?</p>
                              </Row>
                              <Row className="mx-1 rounded">
                                <Table bordered hover>
                                  <tbody>
                                    <tr>
                                      <th scope="col">Name</th>
                                      <td>{dca.name}</td>
                                    </tr>
                                    <tr>
                                      <th scope="column">Retailer</th>
                                      <td>{orgSub.retailer_name}</td>
                                    </tr>
                                    <tr>
                                      <th scope="column">Collection Type</th>
                                      <td>{displayStringAsLabel(orgSub.collection_type)}</td>
                                    </tr>
                                    <tr>
                                      <th scope="column">Unique Identifier</th>
                                      <td>{dca.id}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Row>
                            </DeleteModal>
                          </div>
                        </div>
                        <span
                          className="text-gizmo-grey"
                        >
                          Account Identifier:&nbsp;
                          {dca.id}
                        </span>
                      </div>
                    </div>
                  ))
                }
              </div>
            ))
          }
          <div
            hidden={organizationSubscriptions.length > 0}
          >
            You do not have any collection types that require settings.
            If you believe this is an error, please contact
            <HelpLinkButton />
            .
          </div>
        </div>
      </div>
    </div>
  );
}
